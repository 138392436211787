
import {Component, Vue} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import {parsePhoneNumberFromString, AsYouType, getCountryCallingCode, CountryCode} from 'libphonenumber-js';

@Component({})
export default class Auth extends Vue {

  phoneNumber = '+7';
  password = '';

  errorAlertText = '';

  getPhoneNumberFormat() {

    let pastedData = this.phoneNumber.replace(/[^+\d]/g, '');

    if (!pastedData.startsWith('+')) {
      pastedData = '+' + pastedData;
    }

    // Если номер начинается с "+8", заменяем его на "+7"
    if (pastedData.startsWith('+8')) {
      pastedData = '+7' + pastedData.slice(2);
    }

    if (pastedData.startsWith('8')) {
      pastedData = '+7' + pastedData.slice(1);
    }

    return pastedData;

  }

  formatPhoneNumberPaste() {

    setTimeout(() => {

      let pastedData = this.getPhoneNumberFormat();

      if (pastedData.startsWith('+') && pastedData.length === 11) {
        pastedData = '+7' + pastedData.slice(1);
      }

      this.phoneNumber = pastedData;
      this.formatPhoneNumber();

    }, 200);
  }

  formatPhoneNumber() {

    setTimeout(() => {
      let pastedData = this.getPhoneNumberFormat();


      let countryCode = this.detectCountry(pastedData);
      if (!countryCode) {
        return;
      }

      // Применяем маску и форматируем номер
      const parsedNumber = parsePhoneNumberFromString(pastedData, countryCode);

      if (pastedData === getCountryCallingCode(countryCode)) {
        this.phoneNumber = pastedData;
        return;
      }

      if (parsedNumber) {
        const formatter = new AsYouType(countryCode || undefined);
        this.phoneNumber = formatter.input(pastedData);
        return;
      }

      this.phoneNumber = pastedData;

    }, 200);
  }

  loginIn() {

    AppStore.showLoader();
    Http.post('/login/in',
        {phoneNumber: this.phoneNumber, password: this.password})
        .then((value: any) => {
          localStorage.setItem('token', value.token);
          document.location.href = '/';
        }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        case 'phone_or_password_invalid':
          this.errorAlertText = 'Введен неверный логин или пароль';
          (this.$refs.password as HTMLInputElement).focus();
          break;
        default:
          Http.systemError(HttpError.error);
      }
    });

  }

  created() {
    AppStore.showLoader();
    Http.get('/login/check',).then((value: any) => {

    }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        case 'already_logged':
          this.$router.push('/');
          break;
        default:
          Http.systemError(HttpError.error);
      }
    });

  }

  detectCountry(phone: string): CountryCode | null {
    // Если номер не начинается с "+", добавляем его для унификации
    if (!phone.startsWith('+')) {
      phone = '+' + phone;
    }

    // Проверка России и Казахстана
    if (phone.startsWith('+7')) {
      const nextThreeDigits = phone.slice(2, 5);
      if (/^(0|6|7)\d{2}$/.test(nextThreeDigits)) {
        return 'KZ'; // Казахстан
      }
      return 'RU'; // Россия
    }

    // Список кодов стран (СНГ + Китай + все страны Европы + другие популярные страны)
    const countryPrefixes: Record<string, string> = {
      // СНГ
      '+7': 'RU',    // Россия
      '+375': 'BY',  // Беларусь
      '+380': 'UA',  // Украина
      '+374': 'AM',  // Армения
      '+994': 'AZ',  // Азербайджан
      '+995': 'GE',  // Грузия
      '+996': 'KG',  // Кыргызстан
      '+998': 'UZ',  // Узбекистан
      '+992': 'TJ',  // Таджикистан
      '+993': 'TM',  // Туркменистан
      '+373': 'MD',  // Молдова
      '+991': 'KZ',  // Казахстан (официальный доп. код)

      // Китай
      '+86': 'CN',   // Китай

      // Европа
      '+43': 'AT',   // Австрия
      '+32': 'BE',   // Бельгия
      '+359': 'BG',  // Болгария
      '+385': 'HR',  // Хорватия
      '+357': 'CY',  // Кипр
      '+420': 'CZ',  // Чехия
      '+45': 'DK',   // Дания
      '+372': 'EE',  // Эстония
      '+358': 'FI',  // Финляндия
      '+33': 'FR',   // Франция
      '+49': 'DE',   // Германия
      '+30': 'GR',   // Греция
      '+36': 'HU',   // Венгрия
      '+354': 'IS',  // Исландия
      '+353': 'IE',  // Ирландия
      '+39': 'IT',   // Италия
      '+371': 'LV',  // Латвия
      '+370': 'LT',  // Литва
      '+352': 'LU',  // Люксембург
      '+356': 'MT',  // Мальта
      '+31': 'NL',   // Нидерланды
      '+47': 'NO',   // Норвегия
      '+48': 'PL',   // Польша
      '+351': 'PT',  // Португалия
      '+40': 'RO',   // Румыния
      '+421': 'SK',  // Словакия
      '+386': 'SI',  // Словения
      '+34': 'ES',   // Испания
      '+46': 'SE',   // Швеция
      '+41': 'CH',   // Швейцария
      '+44': 'GB',   // Великобритания
      '+377': 'MC',  // Монако
      '+378': 'SM',  // Сан-Марино
      '+423': 'LI',  // Лихтенштейн
      '+20': 'EG',   // Египет (часто используется в Европе)

      // Другие популярные страны
      '+1': 'US',    // США / Канада
      '+55': 'BR',   // Бразилия
      '+61': 'AU',   // Австралия
      '+81': 'JP',   // Япония
      '+91': 'IN',   // Индия
      '+52': 'MX',   // Мексика
      '+82': 'KR',   // Южная Корея
      '+971': 'AE',  // ОАЭ
      '+90': 'TR',   // Турция
    };

    for (const prefix in countryPrefixes) {
      if (phone.startsWith(prefix)) {
        return countryPrefixes[prefix] as CountryCode;
      }
    }
    return null;

  }

}

